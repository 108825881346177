@mixin material-override($mat-theme) {
  .mat-form-field-suffix .mat-mdc-icon-button {
    font-size: inherit;
  }

  .mdc-notched-outline__notch {
    border-right: none;
  }

  .mat-mdc-card {
    color: var(--on-surface);
    box-shadow:
      0 1px 1px -2px rgb(0 0 0 / 0.2),
      0 1px 4px 1px rgb(0 0 0 / 0.2),
      1px 1px 4px 0 rgb(0 0 0 / 0.2);
    --mdc-elevated-card-container-shape: 0;

    .mat-mdc-card-header-text {
      display: none;
    }

    .mat-mdc-card-content {
      font-size: 16px;
      padding-top: 16px;
    }

    .mat-mdc-card-title {
      margin-bottom: 8px;
    }
  }

  .mat-mdc-tab.mdc-tab {
    min-width: 9rem;
  }

  .mat-mdc-tab-header,
  .mat-mdc-tab-nav-bar {
    .mat-mdc-tab-label-container,
    .mat-mdc-tab-link-container {
      background-color: var(--control-background);

      .mdc-tab-indicator__content--underline {
        border-top-width: 0.175rem;
      }

      .mdc-tab__text-label {
        color: var(--on-surface);
        opacity: 1;
        font-weight: bold;
      }
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;

      mat-tab-body.mat-mdc-tab-body {
        height: 100%;

        .mat-mdc-tab-body-content {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .mat-mdc-table {
    thead {
      th.mat-mdc-header-cell {
        color: var(--table-header);
        font-weight: bold;
        line-height: 1.45;
      }
    }

    tbody {
      tr.mat-row:hover,
      tr.mat-row:focus {
        background: var(--table-accent);
      }
    }

    tbody td.mat-mdc-cell,
    thead th.mat-mdc-header-cell {
      border-bottom-color: var(--table-accent);
      border-bottom-width: 1.5pt;
      font-size: inherit;
    }

    tr.mat-mdc-footer-row td {
      font-weight: bold;
    }
  }

  .mat-mdc-dialog-container {
    --mdc-dialog-subhead-color: var(--color-on-body);
    --mdc-dialog-supporting-text-color: var(--color-on-body);

    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 0;
    }

    .mat-mdc-dialog-title {
      padding-top: 12px;
    }

    .mat-mdc-dialog-actions {
      padding-left: 24px;
      padding-bottom: 12px;
    }

    .mat-mdc-dialog-content {
      --mdc-dialog-supporting-text-size: var(--font-primary);
    }
  }

  .mat-x-tab-group-navigation {
    .mat-mdc-tab-header {
      margin: 0 3rem 1rem 3rem;
    }

    .mat-mdc-tab-body-wrapper {
      margin: 0 3rem 0 3rem;
    }
  }

  .mat-mdc-menu-panel,
  .mat-expansion-panel,
  .mat-accordion,
  .mat-button-toggle-group-appearance-standard {
    --mat-expansion-container-shape: 0;
    border-radius: 0;
    @apply drop-shadow;
  }

  .mat-calendar-body-cell-content {
    --mat-datepicker-calendar-date-selected-state-background-color: var(--color-secondary);
    --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in srgb, var(--color-secondary), transparent 30%);
  }

  .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: var(--color-body);
  }
}
